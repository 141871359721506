import React, { useEffect, useMemo } from 'react'
import moment from 'moment'
import {
  useFlags,
  useLazyFetchRequest,
  Device,
  DeviceClient,
  BillingCyclePeriodsClient,
  UsageData
} from '@monetization/hpaip-notification-rules-react'
import { PrintedPagesChart } from '../PrintedPagesChart'
import { LoadingChartSpinner } from '../PrintedPagesChart/styles'

import {
  NoDataMessageBox,
  Header,
  LeftText,
  RightText,
  PagesText,
  DateText,
  DailyChart,
  Divider
} from './styles'
import { getTotalPagesPrinted } from '../utils'
import MonthlyUsageCard from '../../MonthlyUsageCard'
import { useHistoryContext } from '../../../../hooks/useHistoryContext'

export const BillingUsageData = ({
  printerData,
  selectedBillingCyclePeriod
}) => {
  const { t, authProvider, stack } = useHistoryContext()
  const { printer } = printerData
  const deviceClient = useMemo(
    () => new DeviceClient(authProvider, stack),
    [authProvider, stack]
  )
  const deviceId = useMemo(
    () => printer?.entityDetails?.uniqueDeviceId || null,
    [printer?.entityDetails?.uniqueDeviceId]
  )
  const billingCyclePeriodsClient = useMemo(() => {
    return new BillingCyclePeriodsClient(authProvider, stack)
  }, [authProvider, stack])
  const [fetchBillingCycleUage, billingCycleResponse] =
    useLazyFetchRequest<UsageData>(() => {
      if (!selectedBillingCyclePeriod?.value) return

      return billingCyclePeriodsClient.getBillingCycleUsageByBillingCycleId(
        flags.bizlogicId,
        printer.subscriptionId,
        printer.entityId,
        selectedBillingCyclePeriod.value
      )
    })

  const [fetchDevice, fetchDeviceState] = useLazyFetchRequest<Device>(
    async () => {
      return (await deviceClient.getDeviceCacheInfo(deviceId)).data
    }
  )

  useEffect(() => {
    if (!deviceId) return

    fetchDevice()
  }, [fetchDevice, deviceId])

  const flags = useFlags()
  const { showUsageData } = flags
  useEffect(() => {
    if (printerData && selectedBillingCyclePeriod?.value) {
      fetchBillingCycleUage()
    }
  }, [fetchBillingCycleUage, selectedBillingCyclePeriod, printerData])
  const { isLoading, isError } = useMemo(() => {
    return {
      isLoading: fetchDeviceState?.loading || billingCycleResponse?.loading,
      isError:
        billingCycleResponse?.error ||
        (!billingCycleResponse?.loading && !billingCycleResponse?.data)
    }
  }, [fetchDeviceState, billingCycleResponse])

  const summaryData = useMemo(() => {
    if (!billingCycleResponse?.data) return
    const {
      printPlanPageAllowance,
      printPlanPageAllowancePrinted,
      printPlanRolloverPageAllowance,
      printPlanRolloverPageAllowancePrinted,
      printPlanRolloverPageAllowanceMax,
      printPlanOveragePagesPrinted,
      printPlanOveragePageBlocks,
      monthlyOveragePageBlockSize,
      billingCycleId,
      billingStartDate,
      billingEndDate,
      dailyDetails
    } = billingCycleResponse?.data

    return {
      printPlanPageAllowance,
      printPlanPageAllowancePrinted,
      printPlanRolloverPageAllowance,
      printPlanRolloverPageAllowancePrinted,
      printPlanRolloverPageAllowanceMax,
      printPlanOveragePagesPrinted,
      printPlanOveragePageBlocks,
      monthlyOveragePageBlockSize,
      pricingPlanId: billingCycleId,
      billingStartDate,
      billingEndDate,
      dailyDetails
    }
  }, [billingCycleResponse])

  const totalPagesPrinted = useMemo(
    () => getTotalPagesPrinted(summaryData),
    [summaryData]
  )

  const lastUpdatedAt =
    !isLoading && fetchDeviceState?.data?.lastUpdatedAt
      ? moment
          .utc(fetchDeviceState?.data?.lastUpdatedAt)
          .format('MMMM DD, YYYY h:mm A')
      : false

  if (!showUsageData || isError) {
    return null
  }

  if (isLoading) {
    return <LoadingChartSpinner data-testid="loading-chart-spinner" />
  }

  return (
    <>
      <Header>
        <LeftText>
          {t('history.cards.billing.chart_section.title', 'Printed Pages')}
        </LeftText>
        <RightText>
          <PagesText>
            {t('history.cards.billing.chart_section.total-pages', {
              amount: totalPagesPrinted,
              defaultValue: '{{amount}} Total Pages Printed'
            })}
          </PagesText>
          {lastUpdatedAt && (
            <DateText>
              {t('history.cards.billing.chart_section.as-of', {
                period: lastUpdatedAt,
                defaultValue: 'as of {{period}}',
                interpolation: { escapeValue: false }
              })}
            </DateText>
          )}
        </RightText>
      </Header>
      {totalPagesPrinted ? (
        <>
          <DailyChart>
            <PrintedPagesChart
              usageEntitlement={summaryData}
              printerData={printerData}
            />
          </DailyChart>
          <Divider />
          <MonthlyUsageCard planUsageDetails={summaryData} />
        </>
      ) : (
        <NoDataMessageBox>
          {t(
            'history.cards.billing.no-data',
            'No pages were printed in this period'
          )}
        </NoDataMessageBox>
      )}
    </>
  )
}
