import React, { createContext, useContext } from 'react'

// the RenderControlContext is used to control force updates on veneer
// specific components which are not re-rendering when their props change

interface RenderControlContextProps {
  renderCount: number
  triggerRender: React.DispatchWithoutAction
}

const RenderControlContext = createContext<RenderControlContextProps>({
  renderCount: 0,
  triggerRender: () => 0
})

const useRenderControlContext = () => useContext(RenderControlContext)

export { useRenderControlContext }
