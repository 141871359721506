import IconInfo from '@veneer/core/dist/esm/scripts/icons/icon_info'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import { tokens } from '@veneer/tokens'
import React, { useMemo, useRef } from 'react'
import styled from 'styled-components'
import { useWindowSize } from '../../../hooks'

const { gray9, gray12, white } = tokens.color
const { size1, size0, size2, lineHeight2, lineHeight1 } = tokens.typography

const {
  size1: layoutSize1,
  size2: layoutSize2,
  size3: layoutSize3,
  xsMax
} = tokens.layout

export const GenericText = styled.p`
  font-size: ${size1};
  line-height: ${lineHeight2};
  margin: 0;
`
export const ProgressBarLabel = styled(GenericText)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${layoutSize3};
  color: ${gray12};
  font-size: ${size2};
  line-height: ${lineHeight2};

  @media (max-width: ${xsMax}) {
    font-size: ${size0};
    line-height: ${lineHeight1};
  }
`

export const ProgressBarQuantity = styled.span`
  text-align: right;
  font-size: ${size1};

  @media (max-width: ${xsMax}) {
    font-size: ${size0};
    line-height: ${lineHeight1};
  }
`

export const ProgressBarDescription = styled(GenericText)`
  font-size: ${size0};
  line-height: ${lineHeight1};
  margin-top: ${layoutSize2};
`

export const StyledIconInfo = styled(IconInfo)`
  vertical-align: middle;
  margin-left: ${layoutSize1};
`

export const TooltipContent = styled.div`
  max-width: 200px;
  text-align: center;
`
export const ProgressIndicatorWrapper = styled.div<{ hasMarkerLabel: boolean }>`
  position: relative;
  ${({ hasMarkerLabel }) => (hasMarkerLabel ? 'padding-bottom: 27px;' : '')}
`

export const StyledProgressIndicator = styled(ProgressIndicator)<{
  $color: string
}>`
  && {
    [role='progressbar'] {
      border-radius: 4px;
      height: 8px;

      & > :last-child {
        border-radius: 4px;
        background: ${({ $color }) => $color};
      }
    }
  }
`

const Tag = styled.div<{ position: string; hasLabel: boolean }>`
  position: absolute;
  top: 0;
  left: ${({ position }) => position}%;
  transform: translateX(-50%);
  width: 4px;
  height: 8px;
  background: ${white};

  :after {
    ${({ hasLabel }) => (hasLabel ? 'content: "";' : '')}
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    width: 1px;
    height: 9px;
    background: ${gray9};
  }
`

const Label = styled.label<{ position: string }>`
  position: absolute;
  bottom: 0;
  left: ${({ position }) => position};
  transform: translateX(-50%);
  font-size: ${size0};
  line-height: ${lineHeight1};
  white-space: nowrap;
`

export interface MarkerProps {
  position: number
  label: string
  indicatorRef: HTMLDivElement
}

export const Marker: React.FC<MarkerProps> = ({
  position,
  label,
  indicatorRef
}) => {
  const labelRef = useRef<HTMLLabelElement>()
  const windowSize = useWindowSize()

  const labelPosition = useMemo(() => {
    if (indicatorRef && labelRef?.current) {
      const indicatorWidth = indicatorRef?.offsetWidth
      const labelWidth = labelRef.current.offsetWidth
      const positionInPixels = (position / 100) * indicatorWidth

      if (positionInPixels + labelWidth / 2 > indicatorWidth) {
        return `${indicatorWidth - labelWidth / 2}px`
      }

      if (positionInPixels < labelWidth / 2) {
        return `${labelWidth / 2}px`
      }
    }

    return `${position}%`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorRef, position, windowSize])

  return (
    <>
      <Tag
        position={position.toFixed(2)}
        hasLabel={!!label}
        data-testid="tag"
      />
      {label && (
        <Label ref={labelRef} position={labelPosition}>
          {label}
        </Label>
      )}
    </>
  )
}
