import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_PRODUCTS } from '../graphql'
import { SubscriptionOptionsResponse } from '@monetization/hpaip-notification-rules-react'
import type { InkPlanInfo, InkPlan } from '../../types/planInfo'
import type {
  ProductResult,
  UseGetProductsVariables
} from '../../types/graphql'

const useGetProducts = (variables?: UseGetProductsVariables) => {
  return useQuery(GET_PRODUCTS, { variables })
}

const useLazyGetProducts = () => useLazyQuery<ProductResult>(GET_PRODUCTS)

const findOptionsInkPlan = (
  optionsData: SubscriptionOptionsResponse,
  sku: string
): InkPlan | null => {
  const optionDetails = optionsData?.entities[0]?.edit
  const inkPlan = optionDetails?.find(
    (plan) => plan?.product?.value?.productSku === sku
  )
  if (!inkPlan) return null
  return {
    sku: inkPlan?.product?.value?.productSku,
    pages: inkPlan?.product?.value?.pagesPerMonth ?? 0,
    price: inkPlan?.product?.value?.price?.cost ?? 0,
    rolloverPages: inkPlan?.product?.value?.rolloverPages ?? 0,
    additionalPages: inkPlan?.product?.value?.overageBlockSize ?? 0,
    additionalPagesPrice: inkPlan?.product?.value?.overageBlockPrice ?? 0
  }
}

const getInkPlanDetails = (
  optionsData: SubscriptionOptionsResponse,
  results: ProductResult,
  sku: string
): InkPlanInfo | null => {
  const plans = findOptionsInkPlan(optionsData, sku)

  const printerBundle = results?.products?.items[0]
  const inkBundle = printerBundle.items.reduce((accumulator, item) => {
    item.options.forEach((option) => {
      if (option.product.hp_product_type_label === 'leaseReceivable') {
        accumulator.push(option)
      }
    })
    return accumulator
  }, [])
  const priceLowValue =
    inkBundle[0]?.product?.price?.regularPrice?.amount?.value ?? 0
  plans.priceLowValue = priceLowValue
  return plans
}

export { useGetProducts, useLazyGetProducts, getInkPlanDetails }
