import React, { useEffect, useState } from 'react'
import { SubscriptionsStateClient } from '@monetization/hpaip-notification-rules-react'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import MainComponent from '../MainComponent'
import ErrorSection from '../ErrorSection'
import { useHistoryContext } from '../../hooks/useHistoryContext'

const InitialComponent = () => {
  const { authProvider, stack, t } = useHistoryContext()
  const [subscriptionId, setSubscriptionId] = useState<string>('')
  const [tenantId, setTenantId] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const getAllSubscriptions = async () => {
    const subscriptionsClient = new SubscriptionsStateClient(
      authProvider,
      stack
    )
    setLoading(true)

    try {
      const subscriptions = await subscriptionsClient.getAllSubscriptions()
      setSubscriptionId(subscriptions?.[0]?.subscriptionId)
      setTenantId(subscriptions?.[0]?.tenantId)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getAllSubscriptions()
  }, [])

  return (
    <>
      {subscriptionId && tenantId ? (
        <MainComponent
          subscriptionId={subscriptionId}
          tenantId={tenantId}
          data-testid="main-component"
        />
      ) : (
        <LoadingHandler
          loading={loading}
          error={error}
          customError={
            <ErrorSection
              title={t('error-section.title', 'Something went wrong')}
              description={t(
                'error-section.description',
                'Your print history did not load.'
              )}
              btnText={t('error-section.button', 'Please refresh page')}
            />
          }
          $position="absolute"
          data-testid="loading"
        />
      )}
    </>
  )
}

export default InitialComponent
