import IconPdf from '@veneer/core/dist/esm/scripts/icons/icon_pdf'
import React, { useEffect } from 'react'
import { Container } from './styles'
import { Statement } from 'src/types/Statements'
import { jWebReady } from '@jarvis/jweb-core'
import { useHistoryContext } from 'src/hooks/useHistoryContext'
import { IconColor, IconContainer, LinkText } from '../PlanDetailsCard/styles'
import { CommerceBillingCycleClient } from '@monetization/hpaip-notification-rules-react'
import { Stack } from '@jarvis/web-stratus-client'
interface DownloadLink {
  data: string
  message: string
  filename: string
  link: string
}
type StatementDownloadLinkProps = {
  statement: Statement
  showDownloadErrorNotification?: () => void
}
export default function StatementDownloadLink({
  statement,
  showDownloadErrorNotification
}: StatementDownloadLinkProps) {
  const [isInWebview, setIsInWebview] = React.useState(false)
  const { authProvider, stack, t } = useHistoryContext()
  const baseUrl = {
    [Stack.prod]: 'https://hpsmart.com',
    [Stack.stage]: 'https://hpsmartstage.com',
    [Stack.pie]: 'https://hpsmartpie.com',
    [Stack.dev]: 'https://hpsmartpie.com'
  }
  const url = baseUrl[stack]
  const commerceBillingCycleClient = new CommerceBillingCycleClient(
    authProvider,
    stack
  )
  useEffect(() => {
    const checkWebview = async () => {
      const jweb = await jWebReady

      setIsInWebview(
        jweb?.isNative ||
          jweb?.platform !== 'web' ||
          navigator?.userAgent?.includes('WebView')
      )
    }

    checkWebview()
  }, [])

  return (
    <Container>
      {isInWebview ? (
        <a
          href={window?.location?.href ?? url}
          target="_blank"
          rel="noreferrer"
          data-testid="statement-dl-link-data-a-tag"
          onClick={() => {
            console.log('StatementsDownloadLinkClicked')
          }}
        >
          <IconContainer>
            <IconColor>
              <IconPdf filled size={24} />
            </IconColor>
            <LinkText>
              {t(
                'history.cards.PreviousBilling.download-invoice',
                'Previous invoice'
              )}
            </LinkText>
          </IconContainer>
        </a>
      ) : (
        <div
          data-testid="statement-dl-link-data"
          onClick={async () => {
            try {
              const pdfWindow = window.open()
              if (!pdfWindow) {
                throw new Error('Failed to open new window.')
              }

              const downloadLink: DownloadLink =
                await commerceBillingCycleClient.getStatementDownload(statement)
              if (!downloadLink) {
                throw new Error('No download link returned.')
              }

              const documentTitle =
                downloadLink.filename || downloadLink.message
              pdfWindow.document.write(
                `<iframe id="${documentTitle}" type="application/pdf" width="100%" height="100%" style="border-width: 0" src="data:${downloadLink.data}"></iframe>`
              )
              pdfWindow.document.body.style.margin = '0'
              pdfWindow.document.title = documentTitle
            } catch (error) {
              console.log(JSON.stringify(error ?? ''))
              showDownloadErrorNotification()
            }
          }}
        >
          <IconContainer>
            <IconColor>
              <IconPdf filled size={24} />
            </IconColor>
            <LinkText>
              {t(
                'history.cards.PreviousBilling.download-invoice',
                'Previous invoice'
              )}
            </LinkText>
          </IconContainer>
        </div>
      )}
    </Container>
  )
}
