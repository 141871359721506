import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'

import styled from 'styled-components'

import tokens from '@veneer/tokens'

const { size3 } = tokens.layout

export const LoadingChartSpinner = styled(ProgressIndicator)`
  && {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: ${size3};

    // try to hide loading indicator if its duplicated
    & + & {
      display: none;
    }
  }
`
