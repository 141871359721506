import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { color, layout, typography } = tokens

export const DurationSubHeader = styled.p`
  font-size: ${typography.size0};
  line-height: ${typography.lineHeight1};
`

export const DividerLine = styled.div`
  margin: ${layout.size5} 0;
  height: 1px;
  background-color: ${color.gray3};
`

export const EstimatedPriceBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${layout.size2};
`

export const EstimatedCardlabel = styled.p`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
`
export const EstimatedCardlabelColor = styled.p`
  color: #ff0000;
`

export const InvoiceIconBlock = styled.div`
  margin-top: ${layout.size8};
  color: ${color.hpBlue7};
`

export const InvoiceIconColor = styled.span`
  svg {
    color: ${color.hpBlue7};
  }
`

export const InvoiceIconContainer = styled.div`
  display: flex;
  align-items: stretch;
  gap: ${layout.size2};
`

export const InvoiceLinkText = styled.div`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};
  cursor: pointer;
  color: ${color.hpBlue7};
`

export const EstimatedCard = styled.div``
