import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { color, layout, typography } = tokens

export const ErrorContainer = styled.div`
  text-align: center;
  margin: ${layout.size6} auto;
`
export const Title = styled.p`
  font-size: ${typography.size5};
  color: ${color.gray12};
  line-height: ${typography.lineHeight5};
`

export const Description = styled.p`
  margin-top: ${layout.size1};
  font-size: ${typography.size2};
  color: ${color.gray12};
  line-height: ${typography.lineHeight3};
`

export const StyledButton = styled.div`
  margin-top: ${layout.size6} !important;
`
