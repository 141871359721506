import React, { FC } from 'react'
import { PlanUsageDetails } from '../../../types'
import isNil from '../../../utils/isNil'
import RolloverProgress from './RolloverProgress'
import PlanProgress from './PlanProgress'
import AdditionalProgress from './AdditionalProgress'

export interface MonthlyUsageCardProps {
  planUsageDetails: PlanUsageDetails
}

const MonthlyUsageCard: FC<MonthlyUsageCardProps> = ({ planUsageDetails }) => {
  if (!planUsageDetails) return null

  const {
    printPlanPageAllowancePrinted,
    printPlanPageAllowance,
    printPlanRolloverPageAllowancePrinted,
    printPlanRolloverPageAllowance,
    printPlanOveragePagesPrinted,
    printPlanOveragePageBlocks,
    monthlyOveragePageBlockSize
  } = planUsageDetails

  const showPlanProgress =
    !isNil(printPlanPageAllowancePrinted) && !isNil(printPlanPageAllowance)

  const showRolloverProgress =
    !isNil(printPlanRolloverPageAllowancePrinted) &&
    !isNil(printPlanRolloverPageAllowance) &&
    printPlanRolloverPageAllowance > 0

  const showAdditionalProgress =
    printPlanOveragePageBlocks && monthlyOveragePageBlockSize

  return (
    <>
      {showPlanProgress && (
        <PlanProgress
          pagesPrinted={printPlanPageAllowancePrinted}
          pageAllowance={printPlanPageAllowance}
          data-testid="plan-progress"
        />
      )}
      {showRolloverProgress && (
        <RolloverProgress
          pagesPrinted={printPlanRolloverPageAllowancePrinted}
          pageAllowance={printPlanRolloverPageAllowance}
          data-testid="rollover-progress"
        />
      )}
      {!!showAdditionalProgress && (
        <AdditionalProgress
          pagesPrinted={printPlanOveragePagesPrinted}
          pageBlocks={printPlanOveragePageBlocks}
          pageBlockSize={monthlyOveragePageBlockSize}
          data-testid="additional-progress"
        />
      )}
    </>
  )
}

export default MonthlyUsageCard
