import styled from 'styled-components'
import tokens from '@veneer/tokens'
import Card from '@veneer/core/dist/esm/scripts/card'
const { size2: fontSize2 } = tokens.typography
const { size8: space8 } = tokens.layout

export const StyledCard = styled(Card)`
  && {
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding: ${space8};
    grid-area: billing;
    height: 314px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 355px;
  }
`

export const PrearrivalContainer = styled.div`
  text-align: center;
  width: 65%;
  height: 48px;
  gap: 0px;
  opacity: 0px;
  align-items: center;
`

export const CompleteSetup = styled.p`
  text-align: center;
  font-size: ${fontSize2};
  text-align: center;
`
