import { ApolloClient, HttpLink, from, InMemoryCache } from '@apollo/client'
import { Stack } from '@jarvis/web-stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import fetch from 'cross-fetch'
import { setContext } from '@apollo/client/link/context'

const baseUrl = {
  [Stack.prod]: 'https://us1.api.ws-hp.com/magento/v1/graphql',
  [Stack.stage]: 'https://stage-us1.api.ws-hp.com/magento/v1/graphql',
  [Stack.pie]: 'https://pie-us1.api.ws-hp.com/magento/v1/graphql',
  [Stack.dev]: 'https://pie-us1.api.ws-hp.com/magento/v1/graphql'
}
export const createApolloClient = (
  authProvider: JarvisAuthProvider,
  stack: Stack
) => {
  const authLink = setContext(async (_, { headers }) => {
    const token = await authProvider.getAccessToken()
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })

  const httpLink = new HttpLink({
    uri: baseUrl[stack],
    fetch
  })
  const settings = {
    link: from([authLink, httpLink]),
    cache: new InMemoryCache()
  }

  return new ApolloClient(settings)
}
