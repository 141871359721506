import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { size0, size2, lineHeight1, lineHeight2 } = tokens.typography

const {
  size2: layoutSize2,
  size4: layoutSize4,
  size6: layoutSize6
} = tokens.layout

const { gray12 } = tokens.color

export const ProgressWrapper = styled.div`
  margin-bottom: ${layoutSize4};

  > :not(:last-child) {
    margin-bottom: ${layoutSize4};
  }
`

export const ProgressBarWrapper = styled.div`
  margin-bottom: ${layoutSize6};
`

export const AdditionalProgressText = styled.p`
  margin-top: ${layoutSize2};
  text-align: left;
  color: ${gray12};
  font-size: ${size0};
  line-height: ${lineHeight1};
`

export const PlanAndTrialProgressMessage = styled.p`
  margin-top: ${layoutSize4};
  font-size: ${size2};
  line-height: ${lineHeight2};
  text-align: left;
`
