import {
  getAllDaysMonthsYearsFromInterval,
  DayMonthYear
} from '@monetization/hpaip-notification-rules-react'
import { SupportedSeriesTypes } from '@veneer/highcharts-interface'
import moment from 'moment'
import { ChartColors } from 'src/constants/colors'
import {
  DailyDetails,
  DailyDetailsWithoutDate,
  UsageEntileMent
} from 'src/types'
import { TranslatorFunctionType } from 'src/types/localization'

const getPagesCount = (
  dailyDetails: DailyDetails[],
  formattedDates: string[],
  key: string
) => {
  const pages: number[] = [...Array(formattedDates?.length)].fill(0)

  const detailsDict: Record<string, number> = {}
  dailyDetails.forEach((detail) => {
    detailsDict[detail.date] = detail?.[key as keyof DailyDetailsWithoutDate]
  })

  formattedDates.forEach((date, index) => {
    if (detailsDict[date]) {
      pages[index] = detailsDict[date]
    }
  })

  return pages
}

export const getDailyChartOptions = (
  usageEntitlement: UsageEntileMent,
  t: TranslatorFunctionType
) => {
  const allDates: DayMonthYear[] = getAllDaysMonthsYearsFromInterval(
    usageEntitlement?.billingStartDate,
    usageEntitlement?.billingEndDate
  )

  const categories: string[] = allDates?.map((item) => `${item.day}`)

  const dailyDetails = usageEntitlement?.dailyDetails

  const formattedDates = allDates?.map((item) => {
    const date = new Date(`${item.year}-${item.month}-${item.day}`)
    return moment(new Date(date)).format('YYYY-MM-DD')
  })

  const series: SupportedSeriesTypes[] = [
    {
      name: t('history.daily-chart.tooltip.planned', 'pages printed'),
      data: getPagesCount(
        dailyDetails,
        formattedDates,
        'printPlanPageAllowancePrinted'
      ),
      type: 'column',
      color: ChartColors.PLAN_PAGES,
      index: 2
    },
    {
      name: t('history.daily-chart.tooltip.rollover', 'rollover pages printed'),
      data: getPagesCount(
        dailyDetails,
        formattedDates,
        'printPlanRolloverPageAllowancePrinted'
      ),
      type: 'column',
      color: ChartColors.ROLLOVER_PAGES,
      index: 1
    },
    {
      name: t(
        'history.daily-chart.tooltip.additional',
        'additional pages printed'
      ),
      data: getPagesCount(
        dailyDetails,
        formattedDates,
        'printPlanOveragePagesPrinted'
      ),
      type: 'column',
      color: ChartColors.ADDITIONAL_PAGES,
      index: 0
    }
  ]

  return {
    categories,
    series
  }
}
