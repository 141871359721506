import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import React, { useState } from 'react'
import { TranslatorFunctionType } from '../../types/localization'
import { MfePropsType } from '../../types/mfeProps'

const OnboardingService = ({
  store,
  t,
  useToast
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const { addToast } = useToast()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(mockedNextStageArgument)
  const { nextStage, serviceId } =
    store.useReactStore(React)?.state?.onboarding || {}

  async function finishStage() {
    if (loading) {
      return
    }
    setLoading(true)

    nextStage?.(data)
      .catch(() => {
        addToast({
          id: serviceId,
          text: t(
            'toast.error.default',
            'Something went wrong, please try again.'
          ),
          type: 'negative'
        })
      })
      .finally(() => setLoading(false))
  }

  function getKeyValue(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.currentTarget.name
    const value = event.currentTarget.value

    return { [name]: value }
  }

  if (!serviceId) {
    return <h2>{t('stage.no-service', 'There is no service to load.')}</h2>
  }

  return (
    <>
      {loading && <ProgressIndicator appearance="linear" />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width: '90%',
          margin: 'auto'
        }}
      >
        <h1>{serviceId}</h1>
        <LabelInput
          label={t('stage.form.result', 'Result')}
          name="result"
          value={data?.result || ''}
          onChange={(e) => setData((prev) => ({ ...prev, ...getKeyValue(e) }))}
          disabled={loading}
        />
        <LabelInput
          label={t('stage.form.biz-model', 'Biz Model')}
          name="selectedBizModel"
          value={data?.output?.user?.selectedBizModel || ''}
          disabled={loading}
          onChange={(e) =>
            setData((prev) => ({
              ...prev,
              output: {
                ...prev.output,
                user: getKeyValue(e) as any
              }
            }))
          }
        />
        <LabelInput
          label={t('stage.form.cloud-id', 'Cloud Id')}
          name="cloudId"
          value={data?.output?.device?.cloudId || ''}
          disabled={loading}
          onChange={(e) =>
            setData((prev) => ({
              ...prev,
              output: {
                ...prev.output,
                device: getKeyValue(e) as any
              }
            }))
          }
        />
        <button
          style={{ width: '100%' }}
          disabled={loading}
          onClick={finishStage}
        >
          {t('stage.form.button.confirm', 'End Service')}
        </button>
      </div>
    </>
  )
}

function LabelInput({ onChange, value, name, label, ...props }) {
  return (
    <label>
      {label}
      <input
        style={{ width: '100%' }}
        name={name}
        value={value}
        onChange={onChange}
        {...props}
      />
    </label>
  )
}

const mockedNextStageArgument = {
  result: 'success',
  output: {
    user: {
      selectedBizModel: 'E2E'
    },
    device: {
      cloudId: 'xyz'
    }
  }
}

export default OnboardingService
