import React, { FC } from 'react'
import ProgressBar from '../ProgressBar'
import { ChartColors } from '../../../constants/colors'
import { ProgressBarWrapper } from './styles'
import { useHistoryContext } from '../../../hooks/useHistoryContext'
interface RolloverProgressProps {
  pagesPrinted: number
  pageAllowance: number
}

const RolloverProgress: FC<RolloverProgressProps> = ({
  pagesPrinted,
  pageAllowance
}) => {
  const { t } = useHistoryContext()

  return (
    <ProgressBarWrapper>
      <ProgressBar
        color={
          pagesPrinted <= 0 ? ChartColors.INACTIVE : ChartColors.ROLLOVER_PAGES
        }
        total={pageAllowance}
        current={pagesPrinted}
        label={t('usage-data-progress.rollover.label', 'Rollover pages')}
        tip={t(
          'usage-data-progress.rollover.tip',
          'Unused pages are kept in your account as rollover pages monthly'
        )}
      />
    </ProgressBarWrapper>
  )
}

export default RolloverProgress
