import React, { FC } from 'react'
import IconDocumentCopy from '@veneer/core/dist/esm/scripts/icons/icon_document_copy'
import { useHistoryContext } from '../../../hooks/useHistoryContext'
import { toCurrency } from 'src/utils/currency'

import {
  EstimatedCardlabel,
  DividerLine,
  EstimatedPriceBlock,
  InvoiceIconColor,
  InvoiceLinkText,
  DurationSubHeader,
  InvoiceIconBlock,
  InvoiceIconContainer,
  EstimatedCardlabelColor,
  EstimatedCard
} from './styles'

interface EstimatedChargesCardProps {
  data: any
}

const EstimatedChargesCard: FC<EstimatedChargesCardProps> = ({ data }) => {
  const { t, navigation } = useHistoryContext()

  const handleAllInvoice = () => {
    navigation.push('/account-details/statements')
  }

  const formatDateRange = () => {
    const startDate = data?.customerStartDate || data?.invoiceStartDate
    const endDate = data?.customerEndDate || data?.invoiceEndDate
    return `${startDate} - ${endDate}`
  }

  return (
    <EstimatedCard>
      <EstimatedPriceBlock>
        <EstimatedCardlabel>
          <EstimatedCardlabelColor>
            {t('plan-details.plan-information.plan-charges', 'Plan fee: ')}
          </EstimatedCardlabelColor>
          <DurationSubHeader>{formatDateRange()}</DurationSubHeader>
        </EstimatedCardlabel>
        <EstimatedCardlabel>{toCurrency(data?.planFee)}</EstimatedCardlabel>
      </EstimatedPriceBlock>
      <EstimatedPriceBlock>
        <EstimatedCardlabel>
          {t(
            'plan-details.plan-information.additional-pages',
            'Additional pages'
          )}
        </EstimatedCardlabel>
        <EstimatedCardlabel>
          {toCurrency(data?.additionalFee)}
        </EstimatedCardlabel>
      </EstimatedPriceBlock>
      <EstimatedPriceBlock>
        <EstimatedCardlabel>
          {t('plan-details.plan-information.tax', 'Taxes included')}
        </EstimatedCardlabel>
      </EstimatedPriceBlock>
      <DividerLine />
      <EstimatedPriceBlock>
        <EstimatedCardlabel>
          {t('plan-details.plan-information.current-total', 'Current total: ')}
        </EstimatedCardlabel>
        <EstimatedCardlabel>{toCurrency(data?.total)}</EstimatedCardlabel>
      </EstimatedPriceBlock>
      <InvoiceIconBlock>
        <InvoiceIconContainer>
          <InvoiceIconColor>
            <IconDocumentCopy size={24} />
          </InvoiceIconColor>
          <InvoiceLinkText onClick={handleAllInvoice}>
            {t('plan-details.plan-information.all-invoice', 'All invoices')}
          </InvoiceLinkText>
        </InvoiceIconContainer>
      </InvoiceIconBlock>
    </EstimatedCard>
  )
}

export default EstimatedChargesCard
