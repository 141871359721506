import {
  generateISOStringDate,
  getDayMonthYearFromISOStringDate,
  isSameDayMonthYear,
  DayMonthYear,
  DailyDetail,
  UsageEntitlement
} from '@monetization/hpaip-notification-rules-react'
import tokens from '@veneer/tokens'

const { white: colorWhite, gray9 } = tokens.color

export const getTotalPagesPrinted = (ent: UsageEntitlement) =>
  (ent?.printPlanCreditedPrinted || 0) +
  (ent?.printPlanRolloverPageAllowancePrinted || 0) +
  (ent?.trialPagesPrinted || 0) +
  (ent?.printPlanPageAllowancePrinted || 0) +
  (ent?.printPlanOveragePagesPrinted || 0)

export const xAxisBase = {
  axisTick: { show: false },
  axisLine: {
    show: false
  },
  axisLabel: {
    fontSize: '4px'
  },
  interval: 1
}

export const chartBase = {
  yAxis: {
    splitLine: { show: false },
    minInterval: 10,
    axisLabel: {
      fontSize: '6px'
    }
  },
  tooltip: {
    trigger: 'item'
  },
  grid: {
    left: 20,
    top: 10,
    right: 0,
    bottom: 20
  },
  animationDurationUpdate: 1200,
  media: [
    {
      query: {
        minWidth: 570
      },
      option: {
        grid: {
          left: 25,
          top: 10,
          right: 0,
          bottom: 20
        },
        xAxis: {
          axisLabel: {
            fontSize: '10px'
          }
        },
        yAxis: {
          axisLabel: {
            fontSize: '10px'
          }
        },
        series: undefined
      }
    }
  ]
}

export const seriesBase = (color, tooltipFormatter) => {
  return {
    type: 'bar',
    barGap: '-100%',
    barWidth: '50%',
    barMaxWidth: 8,
    tooltip: {
      backgroundColor: gray9,
      borderColor: gray9,
      textStyle: {
        color: colorWhite
      },
      formatter: tooltipFormatter
    },
    itemStyle: {
      borderRadius: [100, 100, 100, 100],
      color: color
    }
  }
}

type DayDetail = { date: string; entitlement: UsageEntitlement }

function getBaseDailyDetail(
  { day, month, year }: DayMonthYear,
  {
    pricingPlanId,
    printPlanCreditedPrinted,
    printPlanRolloverPageAllowancePrinted,
    trialPagesPrinted,
    printPlanPageAllowancePrinted,
    printPlanOveragePagesPrinted
  }: UsageEntitlement
): DayDetail {
  return {
    date: generateISOStringDate(day, month, year),
    entitlement: {
      pricingPlanId: pricingPlanId,
      printPlanCreditedPrinted: printPlanCreditedPrinted || 0,
      printPlanRolloverPageAllowancePrinted:
        printPlanRolloverPageAllowancePrinted || 0,
      trialPagesPrinted: trialPagesPrinted || 0,
      printPlanPageAllowancePrinted: printPlanPageAllowancePrinted || 0,
      printPlanOveragePagesPrinted: printPlanOveragePagesPrinted || 0
    }
  }
}

export function generateSeries(
  xAxisObject: DayMonthYear[],
  dailyDetails: DailyDetail[],
  pricingPlanId: string
) {
  const result: DayDetail[] = xAxisObject.map((value) => {
    const dayInTheDate = dailyDetails?.find(({ date }: DailyDetail) => {
      return isSameDayMonthYear(getDayMonthYearFromISOStringDate(date), value)
    })

    return getBaseDailyDetail(value, { pricingPlanId, ...dayInTheDate })
  })

  return result
}

export { generateISOStringDate, getDayMonthYearFromISOStringDate }
