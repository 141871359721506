import tokens from '@veneer/tokens'

const { color } = tokens

const ChartColors = {
  PLAN_PAGES: color.royalBlue7,
  ROLLOVER_PAGES: color.hpBlue6,
  ADDITIONAL_PAGES: '#112D3B',
  INACTIVE: color.gray2
}

export { ChartColors }
