import React, { useMemo } from 'react'
import { getDailyChartOptions } from 'src/helpers'
import { UsageEntileMent } from 'src/types'
import { LoadingChartSpinner } from './styles'
import StackedColumnChart from 'src/components/StackedColumnChart'
import { useHistoryContext } from 'src/hooks/useHistoryContext'

type printedPagesChartProps = {
  usageEntitlement: UsageEntileMent
  printerData: any
}

export const PrintedPagesChart: React.FunctionComponent<
  printedPagesChartProps
> = ({ usageEntitlement, printerData }) => {
  const { loading } = printerData
  const { t } = useHistoryContext()
  const chartOptions = useMemo(() => {
    if (loading || !usageEntitlement?.dailyDetails?.length) return

    if (!usageEntitlement) return

    return getDailyChartOptions(usageEntitlement, t)
  }, [loading, t, usageEntitlement])

  if (loading) {
    return (
      <LoadingChartSpinner appearance="circular" behavior="indeterminate" />
    )
  }

  if (!chartOptions) return <></>

  return (
    <StackedColumnChart
      categories={chartOptions?.categories}
      series={chartOptions?.series}
    />
  )
}
