import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { layout, typography } = tokens

export const PreviousBillingCardStyled = styled.div`
  margin-top: ${layout.size6};
  margin-bottom: ${layout.size2};
`

export const HeaderStyled = styled.p`
  font-size: ${typography.size4};
  line-height: ${typography.lineHeight4};
  margin-bottom: ${layout.size8};
`
