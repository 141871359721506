import React, { FunctionComponent } from 'react'
import { elementIds } from '../../../constants/elementIds'
import { useRenderControlContext } from '../../../contexts'
import { AccordionInfo } from '@monetization/hpaip-notification-rules-react'
import {
  AccordionHeaderTitle,
  AccordionContent,
  CardTitle,
  Divider,
  FaqContainer
} from './styles'
import Accordion from '@veneer/core/dist/esm/scripts/accordion'
import { useHistoryContext } from '../../../hooks/useHistoryContext'

interface FaqCardProps {
  faqRef: React.RefObject<HTMLDivElement>
}

export const FaqCard: FunctionComponent<FaqCardProps> = ({ faqRef }) => {
  const { t } = useHistoryContext()
  const { renderCount } = useRenderControlContext()

  const accordionInfo: AccordionInfo[] = [
    {
      header: t(
        'history.cards.faq.accordion.item1.title',
        'How are pages recorded?'
      ),
      content: (
        <AccordionContent id={elementIds.historyFAQ.item1.replace('#', '')}>
          {t(
            'history.cards.faq.accordion.item1.content.part1',
            'Your printer communicates with HP daily to communicate the number of pages printed and current ink levels. Keep your printer connected to Wi-Fi to ensure your HP All-In Plan subscription works correctly and you receive replacement cartridges before you need them.'
          )}
          <br />
          <br />
          {t(
            'history.cards.faq.accordion.item1.content.part2',
            'If pages are printed when the printer is not connected to the Internet (offline), those pages may be recorded on the date the printer comes back online. Learn more in our Terms of Service.'
          )}
        </AccordionContent>
      ),
      elementId: elementIds.historyFAQ.item1
    },
    {
      header: t(
        'history.cards.faq.accordion.item2.title',
        'What does my monthly payment include?'
      ),
      content: (
        <AccordionContent>
          {t(
            'history.cards.faq.accordion.item2.content.part1',
            'When you are enrolled in HP All-In Plan, your monthly charge covers your selected printing plan, the use of HP All-In Plan subscription cartridges, shipping, and recycling. We only send cartridges when you run low, so you wont receive shipments every month. You are not purchasing cartridges with the HP All-In Plan service. See Overview and Update Plan for more information.'
          )}
        </AccordionContent>
      ),
      elementId: elementIds.historyFAQ.item2
    },
    {
      header: t(
        'history.cards.faq.accordion.item3.title',
        'What counts as a printed page?'
      ),
      content: (
        <AccordionContent>
          {t(
            'history.cards.faq.accordion.item3.content.part1',
            'A printed page is a page upon which any amount of ink or toner is placed by your printer, when at least one Instant ink subscription cartridge is installed. Printing on both sides of the same sheet of paper counts as two pages. Completely blank pages, scanned pages, and outgoing faxes are not included in your printed page count.'
          )}
        </AccordionContent>
      ),
      elementId: elementIds.historyFAQ.item3
    }
  ]

  const accordionItems = accordionInfo.map((info, i) => ({
    header: {
      startArea: <AccordionHeaderTitle>{info.header}</AccordionHeaderTitle>,
      centralArea: ' ',
      endArea: ' '
    },
    id: `FaqCardItem-${i}`,
    content: info.content,
    expanded: !!(info.elementId === elementIds.historyFAQ.item1 && renderCount)
  }))

  return (
    <FaqContainer ref={faqRef}>
      <CardTitle>
        {t('history.cards.faq.title', 'Frequently Asked Questions')}
      </CardTitle>
      <Divider />
      <Accordion
        key={renderCount}
        behavior="singleExpand"
        items={accordionItems}
      />
      <Divider />
    </FaqContainer>
  )
}
