import React, { useState, useEffect } from 'react'
import { calculateInvoiceData } from 'src/utils/SubscriptionData'
import {
  CommerceBillingCycleClient,
  SubscriptionStateEnum
} from '@monetization/hpaip-notification-rules-react'
import { useHistoryContext } from '../useHistoryContext'

type useEstimatedChargesReturnValue = {
  data: any
  loading: boolean
  error: boolean
}

const useEstimatedCharges = (printerData): useEstimatedChargesReturnValue => {
  const [estimatedChargeData, setEstimatedChargeData] = useState(null)
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const historyContext = useHistoryContext()
  const { authProvider, stack } = historyContext
  const subscriptionClient = React.useMemo(
    () => new CommerceBillingCycleClient(authProvider, stack),
    [authProvider, stack]
  )

  useEffect(() => {
    const subscriptionId = printerData?.printer?.commerce?.value?.subscriptionId

    const fetchData = async () => {
      try {
        const subscriptionResponse =
          await subscriptionClient.getSubscriptionData(subscriptionId)
        const customerId = subscriptionResponse?.customerId

        let overdueChargesresponse = null
        try {
          overdueChargesresponse = await subscriptionClient.getOverdueCharges(
            customerId
          )
        } catch (error) {
          if (error instanceof Error) {
            setError(true)
          }
        }

        const estimatedChargeResponse =
          await subscriptionClient.getEstimatedchargeStatus(customerId)
        const mergedResponse = estimatedChargeResponse.map((item) => ({
          ...item,
          overdueCharges: overdueChargesresponse?.grossOverdueAmount || 0
        }))
        setEstimatedChargeData(mergedResponse)
      } catch (error) {
        if (error instanceof Error) {
          setError(true)
        }
      } finally {
        setLoading(false)
      }
    }
    if (
      subscriptionId &&
      printerData?.printer?.state !== SubscriptionStateEnum.CANCELED &&
      printerData?.printer?.state !== SubscriptionStateEnum.PENDING
    ) {
      fetchData()
    }
  }, [printerData])

  useEffect(() => {
    const calculateInvoice = () => {
      if (estimatedChargeData) {
        const calculatedData = calculateInvoiceData(estimatedChargeData)
        return calculatedData
      }
      return null
    }

    setData(calculateInvoice())
  }, [estimatedChargeData])

  return { data, loading, error }
}

export default useEstimatedCharges
