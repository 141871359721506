import React from 'react'
import { StyledCard, PrearrivalContainer, CompleteSetup } from './styles'
//import DeliveryTruck from 'src/assets/images/DeliveryTruck.svg'
import { useHistoryContext } from '../../../hooks/useHistoryContext'

export const PrearrivalCard = () => {
  const { t } = useHistoryContext()
  return (
    <StyledCard
      content={
        <PrearrivalContainer>
          <CompleteSetup>
            {t(
              'history.cards.prearrival',
              'Once you complete your HP All-In Plan setup, your print history will appear here.'
            )}
          </CompleteSetup>
        </PrearrivalContainer>
      }
    />
  )
}
