type DayMonthYear = {
  day: number
  month: number
  year: number
}
export function isBetweenDates(start: Date, end: Date): boolean {
  const now = new Date().getTime()

  return now >= start.getTime() && now <= end.getTime()
}

export function getDayMonthYearFromISOStringDate(date: string): DayMonthYear {
  const dateWithoutTime = date.split('T')[0]

  const [year, month, day] = dateWithoutTime.split('-')

  return {
    day: Number.parseInt(day),
    month: Number.parseInt(month),
    year: Number.parseInt(year)
  }
}

export function generateDateFromISOString(date: string): Date {
  const { day, month, year } = getDayMonthYearFromISOStringDate(date)

  return new Date(year, month - 1, day)
}
