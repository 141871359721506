import React, { useState } from 'react'
import { useHistoryContext } from '../../../hooks/useHistoryContext'
import { useGetStatements } from 'src/hooks/useGetStatements/useGetStatements'
import { getFormatDate } from 'src/helpers/date-helper'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import StatementDownloadLink from '../StatementDownloadLink'
import { toCurrency } from 'src/utils/currency'
import {
  Cardlabel,
  Container,
  IconBlock,
  PriceBlock,
  StyledLoader,
  SubHeader
} from '../PlanDetailsCard/styles'
import { HeaderStyled, PreviousBillingCardStyled } from './styles'

const PreviousBillingCard = ({ customerId }) => {
  const { t } = useHistoryContext()
  const { data, error, isLoading } = useGetStatements(customerId)
  const [showInvoiceDownloadNotification, setShowInvoiceDownloadNotification] =
    useState(false)

  const filteredStatements =
    data?.filter((item) => item.resourceType === 'Order') ?? []
  const firstStatement = filteredStatements[0] ?? null
  console.log(showInvoiceDownloadNotification)
  const filterDate = (date) => getFormatDate(new Date(date))
  const startDate =
    firstStatement &&
    filterDate(firstStatement.invoiceLineItems[0]?.startDate).split(' ')
  const endDate =
    firstStatement &&
    filterDate(firstStatement.invoiceLineItems[0]?.endDate).split(' ')

  const LoadingSpinner = () => (
    <StyledLoader>
      <LoadingHandler loading={isLoading} data-testid="loading" />
    </StyledLoader>
  )

  const ErrorHandler = () => (
    <LoadingHandler
      loading={isLoading}
      error={error}
      customError={<p>{t('error-section.title', 'Loading...')}</p>}
    />
  )

  return (
    <PreviousBillingCardStyled>
      <Container>
        {isLoading && <LoadingSpinner />}
        {error && !isLoading && <ErrorHandler />}
        {firstStatement && !isLoading && !error && (
          <>
            <HeaderStyled>
              {t(
                'history.cards.PreviousBilling.title',
                'Previous billing cycle charges'
              )}
            </HeaderStyled>
            <PriceBlock>
              <Cardlabel>
                {t('history.cards.PreviousBilling.invoice', 'Invoice')}
              </Cardlabel>
              <Cardlabel>{toCurrency(firstStatement.totalAmount)}</Cardlabel>
            </PriceBlock>
            <SubHeader>
              {startDate[0]} {startDate[1].replace(',', '')} - {endDate[0]}
              {''} {endDate[1]} {''} {endDate[2]}
            </SubHeader>
            <IconBlock>
              <StatementDownloadLink
                statement={firstStatement}
                showDownloadErrorNotification={() =>
                  setShowInvoiceDownloadNotification(true)
                }
              />
            </IconBlock>
          </>
        )}
      </Container>
    </PreviousBillingCardStyled>
  )
}

export default PreviousBillingCard
