import React from 'react'
import { ProgressBarWrapper } from './styles'
import { ChartColors } from '../../../constants/colors'
import ProgressBar from '../ProgressBar'
import { useHistoryContext } from '../../../hooks/useHistoryContext'

interface PlanProgressProps {
  pagesPrinted: number
  pageAllowance: number
}

const PlanProgress = ({ pagesPrinted, pageAllowance }: PlanProgressProps) => {
  const { t } = useHistoryContext()
  return (
    <ProgressBarWrapper>
      <ProgressBar
        color={
          pagesPrinted <= 0 ? ChartColors.INACTIVE : ChartColors.PLAN_PAGES
        }
        total={pageAllowance}
        current={pagesPrinted}
        label={t('usage-data-progress.plan.label', 'Plan pages')}
        tip={t(
          'usage-data-progress.plan.tip',
          'The number of pages you are enrolled in your monthly plan'
        )}
      />
    </ProgressBarWrapper>
  )
}

export default PlanProgress
