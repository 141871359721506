import styled from 'styled-components'
import tokens from '@veneer/tokens'
import { NotificationBar } from '@monetization/hpaip-ui-shared-components'

const { layout, color } = tokens

export const Container = styled.div<{ hasError: boolean }>`
  padding: 40px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: ${(props) => (props.hasError ? '1fr' : '2fr 1fr')};
  gap: ${layout.size6};

  @media only screen and (max-width: ${layout.smMax}) {
    padding: ${layout.size5};
    display: flex;
    flex-direction: column;
  }
`

export const Header = styled.div`
  height: 84px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  opacity: 0px;
  padding: 32px 32px 0 40px;
`

export const Title = styled.div`
  color: ${color.gray12};
  font-family: Forma DJR UI;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  text-align: left;
`

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`

export const FlexLayout = styled.div`
  grid-row: 1;
  grid-column: 1;
  align-self: stretch;
`

export const StyledNotificationBar = styled(NotificationBar)`
  white-space: break-spaces;
  margin-bottom: ${layout.size4};

  p + p {
    margin-top: ${layout.size4};
  }
`

export const GridContainer = styled.div`
  padding: 40px;
  display: grid;
  gap: ${layout.size6};

  @media only screen and (max-width: ${layout.smMax}) {
    padding: ${layout.size5};
    display: flex;
    flex-direction: column;
  }
`
export const GridMainContainer = styled.div`
  grid-column: 1 / span 2;
  grid-row: 1;
`

export const GridRightContainer = styled.div`
  grid-column: 3;
  grid-row: 1 / span 2;
`

export const GridLeftContainer = styled.div`
  grid-column: 1 / span 2;
  grid-row: 2;
`
