import React from 'react'
import HistoryPage from '../History/HistoryPage'
import {
  SubscriptionStateEnum,
  useCommonNotification
} from '@monetization/hpaip-notification-rules-react'
import {
  LoadingHandler,
  MultipleNotification
} from '@monetization/hpaip-ui-shared-components'
import { useHistoryContext } from '../../hooks/useHistoryContext'
import ErrorSection from '../ErrorSection'

interface MainComponentProps {
  subscriptionId: string
  tenantId: string
}

const MainComponent = ({ subscriptionId, tenantId }: MainComponentProps) => {
  const { t, navigation } = useHistoryContext()
  const data = useCommonNotification(
    'PrintHistory',
    subscriptionId,
    null,
    null,
    navigation
  )
  const cancelState =
    data?.printerData?.printer?.state === SubscriptionStateEnum.CANCELED

  return (
    <>
      {data?.printerData ? (
        <>
          <MultipleNotification
            multiNotificationArray={data?.notificationsList}
          />
          {!cancelState && data?.printerData?.printer !== null && (
            <HistoryPage printerData={data?.printerData} tenantId={tenantId} />
          )}
        </>
      ) : (
        <LoadingHandler
          loading={data?.printerData?.loading}
          error={data?.printerData?.error}
          customError={
            <ErrorSection
              title={t('error-section.title', 'Something went wrong')}
              description={t(
                'error-section.description',
                'Your print history did not load.'
              )}
              btnText={t('error-section.button', 'Please refresh page')}
            />
          }
          $position="absolute"
          data-testid="loading"
        />
      )}
    </>
  )
}

export default MainComponent
