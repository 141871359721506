import { useCallback } from 'react'
import { useHistoryContext } from './useHistoryContext'

export const useToLocaleDate = () => {
  const { localization } = useHistoryContext()
  const toLocaleDate = useCallback(
    (date: Date, options: Intl.DateTimeFormatOptions) => {
      return date.toLocaleDateString(localization.locale, options)
    },
    [localization]
  )

  return toLocaleDate
}
