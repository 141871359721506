import getLocation, { getLocaleFromLocation } from './getLocation'

// to give perfect dates, we need to create date using utc
const convertDateToUTC = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

export const getFormatDate = (date: Date) => {
  // create an options object with the desired format
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short', // use the full name of the month
    day: 'numeric' // use a numeric day
    // use a numeric year
  }
  // const options = { year: 'numeric', month: 'short', day: 'numeric' }
  // return the formatted date string
  return convertDateToUTC(date).toLocaleString(
    getLocaleFromLocation(getLocation()),
    options
  )
}

export const getPastMonthsDate = (minusMonths: number) => {
  let returnedDate = new Date()
  const currentMonth = new Date().getMonth()
  returnedDate = new Date(returnedDate.setMonth(currentMonth - minusMonths))

  return (
    returnedDate.getFullYear() +
    '-' +
    (returnedDate.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    returnedDate.getDate().toString().padStart(2, '0')
  )
}
