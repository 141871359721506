import React, { useEffect, useState } from 'react'
import History from '../src/components/History'
import OnboardingService from '../src/components/OnboardingService'
import { ShellProps } from '../src/types/shell'
import { MfePropsType } from '../src/types/mfeProps'
import projectNames from '../src/configs/projectNames'
import { TranslatorFunctionType } from '../src/types/localization'
import resources from '../src/assets/locale'
import { getStack, getEnvironmentVariables } from '../src/helpers'
import { LDProvider } from '@monetization/hpaip-notification-rules-react'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

type HistoryProps = {
  country?: string
  language?: string
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({
  // used only to test onboarding services
  useOnboardinServiceExample,
  ...props
}: MfePropsType & HistoryProps) {
  // You can find all Shell´s props here. See "../src/types/shell" for more details.
  const [t, setT] = useState<TranslatorFunctionType>()
  const interfaces = window.Shell.v1
  const Component: any = useOnboardinServiceExample
    ? OnboardingService
    : History

  // set environment variables
  const envVariables = getEnvironmentVariables(getStack(props?.stack))
  const clientSideID = envVariables.variables.LAUNCHDARKLY_CLIENTSIDE_ID

  // Create a translator function by providing the resource files
  useEffect(() => {
    if (interfaces.localization) {
      interfaces.localization
        .createTranslatorFunction(resources)
        .then((v) => setT(() => v))
    }
  }, [interfaces])

  // Wait for the translator function creation to show the content
  if (!t) {
    return null
  }

  // You can find the "stack" information by props
  // const stack = props.stack.
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <LDProvider
        clientSideID={clientSideID || '624769cf7b765b1471f2d115'}
        user={{ key: 'test@email.com' }}
      >
        <Component {...{ ...props, ...interfaces, t }} />
      </LDProvider>
    </section>
  )
}
