import React from 'react'
import Button from '@veneer/core/dist/esm/scripts/button'
import { Description, ErrorContainer, StyledButton, Title } from './styles'

interface ErrorSectionProps {
  title: string
  description: string
  btnText: string
}
const ErrorSection = ({ title, description, btnText }: ErrorSectionProps) => {
  const handlePageReload = () => {
    window.location.reload()
  }

  return (
    <ErrorContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <StyledButton>
        <Button onClick={handlePageReload}>{btnText}</Button>
      </StyledButton>
    </ErrorContainer>
  )
}

export default ErrorSection
