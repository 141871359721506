import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { color, layout, typography } = tokens

export const Header = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${layout.size8};
  color: ${color.gray12};

  @media only screen and (max-width: ${layout.smMin}) {
    margin-bottom: ${layout.size6};
  }
`

export const LeftText = styled.p`
  font-size: ${typography.size4};
  line-height: ${typography.lineHeight4};

  @media only screen and (max-width: ${layout.smMin}) {
    display: none;
  }
`

export const RightText = styled.div`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
  font-weight: 400;

  @media only screen and (max-width: ${layout.smMin}) {
    font-size: ${typography.size4};
    line-height: ${typography.lineHeight4};
  }
`

export const PagesText = styled.p``

export const DateText = styled.p``

export const DailyChart = styled.div`
  margin-bottom: ${layout.size8};

  @media only screen and (max-width: ${layout.smMin}) {
    margin-bottom: ${layout.size4};
  }
`

export const Divider = styled.div`
  margin-bottom: ${layout.size6};
  height: 1px;
  background: ${color.gray3};
`

export const NoDataMessageBox = styled.p`
  text-align: center;
  padding: ${layout.size5} 0;
  border-bottom: 1px solid ${color.gray3};

  color: ${color.gray6};
  font-size: ${typography.size5};
  line-height: ${typography.lineHeight5};
`
