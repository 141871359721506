import React, { useMemo, useRef } from 'react'
import Tooltip from '@veneer/core/dist/esm/scripts/tooltip'

import {
  StyledIconInfo,
  ProgressBarDescription,
  ProgressBarLabel,
  ProgressBarQuantity,
  StyledProgressIndicator,
  TooltipContent,
  ProgressIndicatorWrapper,
  Marker
} from './styles'
import { useHistoryContext } from '../../../hooks/useHistoryContext'
interface ProgressBarProps {
  color: string
  total: number
  current: number
  label: string
  tip: string
  description?: string
  marker?: {
    value: number
    afterColor: string
    label: string
  }
}

const ProgressBar = ({
  color,
  total,
  current,
  label,
  description,
  tip,
  marker
}: ProgressBarProps) => {
  const { t } = useHistoryContext()
  const indicatorRef = useRef(null)

  const tooltip = {
    portal: true,
    content: <TooltipContent>{tip}</TooltipContent>
  }

  const markerPosition = ((marker?.value || 0) / total) * 100

  const indicatorColor = useMemo(() => {
    if (!marker?.afterColor) return color

    const markerColor = marker.afterColor
    // marker position + how much to 100%
    const colorDivision = markerPosition + (1 - current / total) * 100

    return `linear-gradient(90deg, ${color} ${colorDivision.toFixed(
      2
    )}%, ${markerColor} 0)`
  }, [marker, markerPosition, color, current, total])

  return (
    <article>
      <ProgressBarLabel>
        <span>
          <label>{label}</label>
          <Tooltip {...tooltip} placement="top" arrow>
            <StyledIconInfo size={20} />
          </Tooltip>
        </span>
        <ProgressBarQuantity>
          {`${current} ${t('history.progress-bar.label.of', 'of')} ${total} ${t(
            'history.progress-bar.label.used',
            'used'
          )}`}
        </ProgressBarQuantity>
      </ProgressBarLabel>
      <ProgressIndicatorWrapper hasMarkerLabel={!!marker?.label}>
        <StyledProgressIndicator
          ref={indicatorRef}
          appearance="linear"
          behavior="determinate"
          $color={indicatorColor}
          value={(current / total) * 100}
        />
        {marker && (
          <Marker
            label={marker.label}
            position={markerPosition}
            indicatorRef={indicatorRef.current}
          />
        )}
      </ProgressIndicatorWrapper>
      {description && (
        <ProgressBarDescription>{description}</ProgressBarDescription>
      )}
    </article>
  )
}

export default ProgressBar
