import moment from 'moment'

export function calculateInvoiceData(estimatedChargeData) {
  const items = estimatedChargeData?.[0]?.items?.[0]

  function formatDate(date) {
    return moment(date?.split('T')[0])
  }

  const invoiceStartDate = moment(items?.invoiceStartDate).format('MMM DD')

  const invoiceEndDate = moment(items?.invoiceEndDate).format('MMM DD, YYYY')

  const customerStartDate = formatDate(items?.customerStartDate).format(
    'MMM DD'
  )
  const customerEndDate = formatDate(items?.customerEndDate).format(
    'MMM DD, YYYY'
  )

  const planFee = calculateFee(estimatedChargeData?.[0]?.items || [], 'REGULAR')

  const overdueFee = estimatedChargeData?.[0]?.overdueCharges

  const total =
    (Number(estimatedChargeData?.[0]?.totalPayableAmount) || 0) +
    (Number(overdueFee) || 0)

  const additionalFee = total - planFee

  return {
    invoiceStartDate,
    invoiceEndDate,
    customerStartDate,
    customerEndDate,
    planFee,
    additionalFee,
    overdueFee,
    total
  }
}

export const calculateFee = (items: any[], lineItemType: string): number => {
  return items.reduce((total, item) => {
    return item.lineItemType === lineItemType
      ? total + parseFloat(item.invoiceAmount)
      : total
  }, 0)
}
