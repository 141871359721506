import React, { FC } from 'react'

import { AdditionalProgressText } from './styles'
import ProgressBar from '../ProgressBar'
import { ChartColors } from '../../../constants/colors'
import { useHistoryContext } from '../../../hooks/useHistoryContext'

interface AdditionalProgressProps {
  pagesPrinted: number
  pageBlocks: number
  pageBlockSize: number
}

const AdditionalProgress: FC<AdditionalProgressProps> = ({
  pagesPrinted,
  pageBlocks,
  pageBlockSize
}) => {
  const { t } = useHistoryContext()

  return (
    <div>
      <ProgressBar
        color={
          pagesPrinted <= 0
            ? ChartColors.INACTIVE
            : ChartColors.ADDITIONAL_PAGES
        }
        total={pageBlocks * pageBlockSize}
        current={pagesPrinted}
        label={t('usage-data-progress.additional.label', 'Additional pages')}
        tip={t(
          'usage-data-progress.additional.tip',
          'You can print additional pages if you have used all of your plan and rollover pages.'
        )}
      />
      <AdditionalProgressText>
        {t('usage-data-progress.additional.text', {
          sets: pageBlocks,
          pages: pageBlockSize,
          defaultValue:
            'You have purchased {{sets}} set of additional pages ({{pages}} pages/set).'
        })}
      </AdditionalProgressText>
    </div>
  )
}

export default AdditionalProgress
