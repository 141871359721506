import React, { FC, useState, useMemo, useEffect } from 'react'
import {
  Cardlabel,
  Divider,
  PriceBlock,
  Header,
  LinkText,
  StyledIconInfo,
  SubHeader
} from '../PlanDetailsCard/styles'

import { InkPlanInfo } from '../../../types/planInfo'
import { useLazyGetProducts, getInkPlanDetails } from '../../../api/services'
import { toCurrency } from 'src/utils/currency'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import { useHistoryContext } from '../../../hooks/useHistoryContext'

interface PlanDetailsCardInfoProps {
  subscriptionId: string
  printerData: any
}
export const PlanDetailsCardInfo: FC<PlanDetailsCardInfoProps> = ({
  subscriptionId,
  printerData
}) => {
  const { t, navigation } = useHistoryContext()

  const [productResults, setProductResults] = useState<any>()
  const [getProducts] = useLazyGetProducts()

  const [isProductLoading, setIsProductLoading] = useState<boolean>(true)
  const [error, setError] = useState<any>()

  const navigateToUpdatePlan = () => {
    navigation?.push('/hp-all-in-print-plan/update-plan')
  }

  useEffect(() => {
    if (printerData?.isLoading || !printerData?.instantInk) return

    const values = printerData?.instantInk?.product?.value
    const { parentProductSku, productSku } = values || {}

    if (!parentProductSku || !productSku) return

    getProducts({
      variables: { sku: parentProductSku },
      onCompleted: (data) => {
        setProductResults(data)
        setIsProductLoading(false)
      },
      onError: () => {
        setIsProductLoading(false)
        setError(true)
      }
    })
  }, [getProducts, printerData])

  const inkPlan: InkPlanInfo = useMemo(() => {
    if (!printerData?.optionsData || !productResults) return null
    const values = printerData?.instantInk?.product?.value
    const { productSku } = values || {}
    const plan = getInkPlanDetails(
      printerData?.optionsData,
      productResults,
      productSku
    )
    return plan
  }, [printerData?.optionsData, productResults, printerData])
  const isPageloading = isProductLoading

  const planPrice = () => {
    return inkPlan?.price + inkPlan?.priceLowValue
  }

  return (
    <>
      <Header>{t('plan-details.title', 'Plan Details')}</Header>
      <SubHeader>
        {t(
          'plan-details.subscription-label',
          `Subscription ID: ${subscriptionId}`
        )}
      </SubHeader>
      <Divider />
      <LoadingHandler
        loading={isPageloading}
        error={error}
        customError={<p>{t('error-section.title', 'Loading...')}</p>}
      >
        <PriceBlock>
          <Cardlabel>{t('plan-details.plan', 'Plan')}</Cardlabel>
          <LinkText onClick={navigateToUpdatePlan}>
            {t('plan-details.update-plan', 'Update plan')}
          </LinkText>
        </PriceBlock>
        <Cardlabel>
          {t('plan-details.plan-information.value', {
            planCharge: toCurrency(planPrice()),
            planPages: inkPlan?.pages,
            defaultValue: '{{planCharge}} plan / {{planPages}} pages a month'
          })}
        </Cardlabel>
        <SubHeader>
          {t('plan-details.plan-information.aditional', {
            additionalPages: inkPlan?.additionalPages,
            additionalCharges: toCurrency(inkPlan?.additionalPagesPrice),
            defaultValue:
              '*Each additional set of {{additionalPages}} pages for {{additionalCharges}}'
          })}

          <StyledIconInfo size={16} />
        </SubHeader>
      </LoadingHandler>
    </>
  )
}
