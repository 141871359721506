import React from 'react'
import { LoadingChartSpinner } from './PrintedPagesChart/styles'
import { StyledCard } from './styles'
import { BillingCardContent } from './BillingCardContent'

export const BillingCard = ({ printerData, billingCycles }) => {
  const { printer, isLoading, called } = printerData

  const { loading } = billingCycles

  const hasDevice = printer && !isLoading && called

  if (!hasDevice) {
    return null
  }
  return (
    <StyledCard
      appearance="dropShadow"
      content={
        loading ? (
          <LoadingChartSpinner />
        ) : (
          <BillingCardContent
            printerData={printerData}
            billingCycles={billingCycles}
          />
        )
      }
    />
  )
}
