import highchartsInterface, {
  Chart,
  ChartProvider,
  SupportedSeriesTypes
} from '@veneer/highcharts-interface'
import React, { FC } from 'react'

interface StackedColumnChartProps {
  categories: string[]
  series: SupportedSeriesTypes[]
}
const StackedColumnChart: FC<StackedColumnChartProps> = (
  props: StackedColumnChartProps
) => {
  const { categories, series } = props
  return (
    <ChartProvider chartInterface={highchartsInterface}>
      <Chart
        type="chart"
        options={{
          chart: {
            type: 'column'
          },
          xAxis: {
            categories,
            lineWidth: 0 // remove x-axis line
          },
          yAxis: {
            min: 0,
            gridLineWidth: 0, // remove y-axis grid lines
            labels: {
              distance: 41
            }
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            column: {
              stacking: 'normal'
            }
          },
          tooltip: {
            formatter: function () {
              return `<p style="padding: 8px 12px">${this.y} ${this.series.name}<p>`
            },
            positioner: function (_labelWidth, _labelHeight, point) {
              return {
                x: point.plotX + this.chart.plotLeft,
                y: point.plotY + this.chart.plotTop
              }
            },
            followPointer: true
          },
          series
        }}
      />
    </ChartProvider>
  )
}

export default StackedColumnChart
