import { useEffect, useState } from 'react'
import { useHistoryContext } from '../useHistoryContext'
import { CommerceBillingCycleClient } from '@monetization/hpaip-notification-rules-react'

export const useGetCustomer = (id: string) => {
  const { authProvider, stack } = useHistoryContext()
  const [getCustomerInfo, setGetCustomerInfo] = useState(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const getCustomer = async () => {
    const commerceBillingCycleClient = new CommerceBillingCycleClient(
      authProvider,
      stack
    )
    setIsLoading(true)
    try {
      const { resourceId } =
        await commerceBillingCycleClient.getCustomerDetailsById(id)
      setGetCustomerInfo(resourceId)
      setIsLoading(false)
    } catch (error) {
      console.log(error, 'error')
      setError(true)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getCustomer()
  }, [])

  return {
    customerId: getCustomerInfo,
    error,
    isLoading
  }
}
