import { useEffect, useState, useCallback } from 'react'
import { useToLocaleDate } from '../useToLocaleDate'
import { useHistoryContext } from '../useHistoryContext'
import {
  SelectOptionType,
  BillingCycleDropdown
} from '../../types/billingCycleDropdown'
import {
  BillingCyclePeriods,
  BillingCyclePeriodsResponse
} from '../../types/billingCyclePeriods'
import { isBetweenDates, generateDateFromISOString } from '../../utils/date'

export function useBillingCycleDropdown(
  data: BillingCyclePeriodsResponse[]
): BillingCycleDropdown {
  const { t } = useHistoryContext()
  const formatCompleteDate = useToLocaleDate()
  const [selectOptions, setSelectOptions] = useState<SelectOptionType[]>([])
  const [selectedBillingCyclePeriod, setSelectedBillingCyclePeriod] =
    useState<SelectOptionType>(null)

  const buildSelectOption = useCallback(
    (item: BillingCyclePeriods): SelectOptionType => {
      const startDate = generateDateFromISOString(item.startDate)
      const endDate = generateDateFromISOString(item.endDate)
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }

      const startDateLabel = formatCompleteDate(startDate, options)
      const endDateLabel = formatCompleteDate(endDate, options)
      const isCurrent = isBetweenDates(startDate, endDate)

      return {
        value: item?.billingCycleId,
        label: `${startDateLabel} - ${endDateLabel} ${
          /**
           * Language key for the current selected option
           * @param {history.cards.billing.select.current} '(Current)' */
          isCurrent
            ? t('history.cards.billing.select.current', '(Current)')
            : ''
        }`,
        isCurrent,
        startDate,
        endDate
      }
    },
    [formatCompleteDate, t]
  )

  useEffect(() => {
    if (!data?.length) return

    const dataItems = data.reduce((acc, data) => {
      return data?.items ? [...acc, ...data.items] : acc
    }, [])

    if (!dataItems) return

    const newSelectOptions: SelectOptionType[] = dataItems
      .map(buildSelectOption)
      .sort((a, b) => b.startDate.getTime() - a.startDate.getTime())
    const currentCycle = newSelectOptions.find(
      (selectOption) => selectOption.isCurrent
    )

    setSelectedBillingCyclePeriod(currentCycle || newSelectOptions[0])
    setSelectOptions(newSelectOptions)
  }, [buildSelectOption, data])

  const handleSelectChange = (selectedOption: SelectOptionType) =>
    setSelectedBillingCyclePeriod(selectedOption)

  return {
    selectedBillingCyclePeriod,
    selectOptions,
    handleSelectChange
  }
}
